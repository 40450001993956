$text-color: rgba(0, 0, 0, 0.7);

$background-dark-color: #7b948d;
$background-light-color: #EEEEEE;

$white: rgb(245,245,246);
$gray: rgb(225,226,225);

$primary-color: #00695C;
$primary-color-light: #439889;
$primary-color-dark: #003d33;
$primary-text-color: #FFF;
$primary-background-color: rgba(255, 255, 255, 0.16);

$secondary-color: #afb42b;
$secondary-color-light: #e4e65e;
$secondary-color-dark: #7c8500;
$secondary-text-color: #000;
$secondary-background-color: rgba(0, 0, 0, 0.16);

