@import '../../../Base/Colors';
[class*="MuiAppBar"].Navigation,
.Navigation{
	background-color: initial;
	background-image: initial;
	box-shadow: initial;
	color: $text-color;
	position: static;
	.logo{
		max-height: 3em;
	}
}
[role="menuitem"]{
	&>a,
	&>p{
		padding-left: 1em;
	}
}
div.close{
	min-width: initial;
	padding: 1em;
	display: flex;
	cursor: pointer;
}