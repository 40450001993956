@import "../../../Base/Styles.scss";
.Home{
	padding: 1.5em 1em;
	.sensors{
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		svg{
			flex-shrink: 1;
			flex-basis: auto;
			@include mq('tablet') {
				flex-basis: 20%;
			}
		}
	}
}