.Auth{
	padding:2em 1.5em;

}
.login{

	.loginContent{
		text-align: center;
	}
}
.App .logout{
	position: absolute;
	bottom: 1em;
	right: 1em;
}