@import './Colors.scss';

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: normal;
	src: local('Roboto'), url('../../public/fonts/Roboto-Medium.ttf') format('truetype');
}
@font-face {
	font-family: 'RobotoMono';
	font-style: normal;
	font-weight: normal;
	src: local('Roboto'), url('../../public/fonts/RobotoMono-Variable.ttf') format('truetype');
}
body,h1,h2,h3 {
	font-family: 'Roboto';
}

pre{
	font-family: 'RobotoMono';
}