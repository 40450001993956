@import './Mixins.scss';
@import './Typography.scss';
@import './Colors.scss';
@import './App.scss';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color:$background-dark-color;
	font-size: 12px;
	@include mq('tablet') {
		font-size: 16px;
	}
	@include mq('phone-wide') {
		font-size: 20px;
	}
}

code, pre {
	font-family: 'RobotoMono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	background-color: $text-color;
	color: $background-light-color;
	line-height: 1.5em;
}
article{
	color: $text-color;
	font-size: 2em;
}
a {
	color: inherit;
	text-decoration: inherit;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(1em + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}