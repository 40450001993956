/* Creating scalable elements (usually images / background images) that maintain a ratio. */
/* USAGE
div {
	@include responsive-ratio(16, 9);
}
*/
@mixin responsive-ratio($x, $y, $pseudo: false) {
	$padding: unquote(($y / $x ) * 100 + '%');

	@if $pseudo {
		&:before {
			@include pseudo($pos: relative);
			width: 100%;
			padding-top: $padding;
		}
	}

	@else {
		padding-top: $padding;
	}
}

/*
input,
textarea {
	@include input-placeholder {
		color: $grey;
	}
}
*/
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}


/* Media queries 
	.site-header {
		padding: 2rem;
		font-size: 1.8rem;

		@include mq('tablet-wide') {
			padding-top: 4rem;
			font-size: 2.4rem;
		}
	}
*/
$breakpoints: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-wide":  1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}